<template>
  <div
    v-if="authUser.name"
    class="the-navbar__user-meta flex items-center">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ authUser.name  }}</p>
      <router-link
        to="/purchases/manage-top-ups/top-up-account"
        class="link-plain">
        <strong>{{ authBalance | dollar }}</strong>
        <strong class="pl-1" style="font-size: 1.2rem !important">+</strong>
      </router-link>
    </div>

    <div
      vs-custom-content
      vs-trigger-click
      class="cursor-pointer"
      style="max-height: 40px"
      v-if="$mq === $enums.mqBreakpoints.MOBILE">

      <vs-avatar
        :src="avatarUrl"
        :text="authUser.name"
        color="primary"
        size="40px"
        class="text-2xl ml-3 m-0"
        @click="$router.push('/settings').catch(() => {})"/>
    </div>

    <vs-dropdown
      vs-custom-content
      vs-trigger-click
      class="cursor-pointer"
      style="max-height: 40px"
      v-else>

      <vs-avatar
        :src="avatarUrl"
        :text="authUser.name"
        color="primary"
        size="40px"
        class="text-2xl ml-3 m-0"/>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/account').catch(() => {})">
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $tc('$General.Account') }}</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/billing').catch(() => {})">
            <feather-icon icon="CreditCardIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ this.$t('$General.Billing') }}</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/purchases').catch(() => {})">
            <feather-icon icon="ShoppingCartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ this.$tc('$General.Shop') }}</span>
          </li>

          <vs-divider class="m-1" />

          <li
            v-if="roleIsMerchant"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="increaseFeedbackModalKey">
            <feather-icon
              icon="StarIcon"
              svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $tc('$General.Feedback') }}</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <terms-and-conditions-link
              class="hover:text-white hover:no-underline w-full"
              style="color: inherit">
              <feather-icon
                icon="FileTextIcon"
                svgClasses="w-4 h-4"/>
              <span class="ml-2">{{ $tc('$General.TermsAndConditions') }}</span>
            </terms-and-conditions-link>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon
              icon="LogOutIcon"
              svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $tc('$General.Logout') }}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

// Components
import TermsAndConditionsLink from '@/layouts/components/TermsAndConditionsLink.vue';

// Others
import { registerSocketEvent, unregisterSocketEvent } from '@/socket';

/**
 * Profile drop down
 *
 * @module layouts/components/navbar/components/ProfileDropDown
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-event {void} logout - app logout
 */
export default {
  components: {
    TermsAndConditionsLink,
  },
  data() {
    return {
      socketEventsListenerId: 'profile-drop-down',
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.auth.user,
      isVerticalNavMenuActive: (state) => state.isVerticalNavMenuActive,
    }),
    ...mapGetters({
      avatarUrl: 'auth/avatarUrl',
      authBalance: 'auth/balance',
      roleIsMerchant: 'auth/roleIsMerchant',
    }),
  },
  mounted() {
    console.log('Register socket event update balance');
    registerSocketEvent(this.$enums.SocketEvents.UPDATE_BALANCE, (tenant) => {
      console.log('socket updated balance event listen');
      console.log('Register socket event update balance');
      this.partialUpdateAuthTenant(tenant);
    },
    this.socketEventsListenerId,
    true);
  },
  beforeDestroy() {
    console.log('Entro aqui en unregister update balance');
    unregisterSocketEvent(
      this.$enums.SocketEvents.UPDATE_BALANCE,
      this.socketEventsListenerId,
    );
  },
  methods: {
    ...mapActions({
      actionLogout: 'auth/logout',
    }),
    ...mapMutations({
      partialUpdateAuthTenant: 'auth/PARTIAL_UPDATE_AUTH_USER_TENANT',
      increaseFeedbackModalKey: 'INCREASE_FEEDBACK_MODAL_KEY',
    }),
    async logout() {
      await this.actionLogout();
      this.$router.push({ name: 'login' }).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.vx-navbar-dropdown.con-vs-dropdown--menu  {
  z-index: 42000 !important;
}
</style>
