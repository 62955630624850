import BaseService from '@/api/base.service';
import configApi from '@/api/config';
import grapePerksAppService from '@/api';

/**
 * feedback service api
 * @class FeedbackService
 */
class FeedbackService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.feedback });
  }

  /**
   * Get analytics feedback for pie chart
   * @function getAnalyticsPie
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  getAnalyticsPie = () => grapePerksAppService.get(
    `${this.baseUrl}/analytics-pie/`,
  ).then((resp) => resp.data);
}

const singletonInstance = new FeedbackService();

/**
 * feedback service api instance
 * @exports api/modules/feedback
 * */
export default singletonInstance;
