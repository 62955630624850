<template>
  <vs-dropdown
    vs-custom-content
    vs-trigger-click
    class="cursor-pointer"
    @blur="onViewNotifications()">
    <feather-icon
      icon="BellIcon"
      class="cursor-pointer mt-1 mr-2 sm:mr-6"
      :badge="unseenNotificationsCount"/>

    <vs-dropdown-menu
      class="notification-dropdown dropdown-custom vx-navbar-dropdown whitespace-normal">

      <div
        class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ unseenNotificationsCount }} {{ $tc('$General.New') }}</h3>
        <p class="opacity-75">{{ $t('AppNotifications') }}</p>
      </div>

      <VuePerfectScrollbar
        ref="mainSidebarPs"
        :key="$vs.rtl"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings">
        <notification-list
          :notifications="unseenNotifications"/>
      </VuePerfectScrollbar>

      <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer">
        <span>
          <router-link
            :to="{ name: 'notifications' }"
            class="link-plain">
            {{ $t('ViewAllNotifications') }}
          </router-link>
        </span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { mapState, mapActions, mapMutations } from 'vuex';
import { registerSocketEvent, unregisterSocketEvent } from '@/socket';

// components
import NotificationList from '@/layouts/components/navbar/components/notifications/NotificationDropDownList.vue';

/**
 * Dropdown to show app notifications
 *
 * @module layouts/components/navbar/components/NotificationDropDown
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object} [settings=Object] - settings to scroll bar
 * @vue-event {void} created - hook to fetch unseen notifications
 * @vue-event {void} onViewNotifications - called on view notification to mark as seen
 */
export default {
  name: 'NotificationDropDown',
  i18n: {
    messages: {
      en: {
        AppNotifications: 'App notifications',
        ViewAllNotifications: 'View all notifications',
      },
    },
  },
  components: {
    VuePerfectScrollbar,
    NotificationList,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.60,
      },
      socketEventsListenerId: 'notification-drop-down',
    };
  },
  computed: {
    ...mapState({
      unseenNotificationsCount: (state) => state.notification.unseenCount,
      unseenNotifications: (state) => state.notification.unseenCollection,
    }),
  },
  created() {
    this.fetchUnseenNotifications();
  },
  mounted() {
    console.log('Register notification socket event');
    registerSocketEvent(this.$enums.SocketEvents.NOTIFICATION, (notification) => {
      console.log('Entro a register socket event notification');
      this.addUnseenNotification(notification);
    },
    this.socketEventsListenerId,
    true);
  },
  beforeDestroy() {
    console.log('Entro aqui en before destroy notification');
    unregisterSocketEvent(
      this.$enums.SocketEvents.NOTIFICATION,
      this.socketEventsListenerId,
    );
  },
  methods: {
    ...mapActions({
      fetchUnseenNotifications: 'notification/fetchUnseenNotifications',
      updateNotificationsAsViewed: 'notification/updateNotificationsAsViewed',
    }),
    ...mapMutations({
      addUnseenNotification: 'notification/ADD_UNSEEN_NOTIFICATION',
    }),
    onViewNotifications() {
      this.updateNotificationsAsViewed();
    },
  },
};

</script>
