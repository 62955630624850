<template>
  <div class="feedback-button">
    <span
      class="cursor-pointer"
      :class="linkWrapperClasses"
      @click="showModal=true">
      <slot>
        <a
          class="flex items-center link-plain cursor-pointer">
          <feather-icon
            icon="StarIcon"
            class="mr-1"
            svgClasses="w-5 h-5"/>

          <span>
          {{ $t('$General.Feedback') }}
          </span>
        </a>
      </slot>
    </span>

    <feedback-modal
      v-model="showModal"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

// Components
import FeedbackModal from '@/layouts/components/feedback/TheFeedbackModal.vue';

/**
 * Feedback button
 *
 * @module layouts/components/FeedbackButton.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} linkWrapperClasses - link wrapper classes
 * @vue-data {boolean} showModal - indicate if show or no the feedback modal
 * @vue-data {Object} model - model to save
 * @vue-event {void} onModalClose - called on modal close
 * @vue-event {void} sendFeedback - send the feedback
 */
export default {
  name: 'TheFeedbackButton',
  components: {
    FeedbackModal,
  },
  props: {
    linkWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      feedbackModalKey: (state) => state.feedbackModalKey,
    }),
  },
  watch: {
    feedbackModalKey() {
      this.showModal = true;
    },
  },
};

</script>
