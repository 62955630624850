<template>
  <a
    :href="$enums.AppLinks.TERMS_AND_CONDITIONS"
    target="_blank"
    class="flex items-center">
    <slot>
      {{ $t('$General.TermsAndConditions') }}
    </slot>
  </a>
</template>

<script>

/**
 * Terms and conditions link
 *
 * @module src/layouts/components/TermsAndConditionsLink
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-event {void} logout - app logout
 */
export default {
  name: 'TermsAndConditionsLink',
};
</script>
