<template>
  <div class="relative">
    <div
      class="vx-navbar-wrapper"
      :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer p-2 mobile-menu-button"
          icon="MenuIcon"
          @click.stop="showSidebar" />

        <bookmarks
          v-if="windowWidth >= 992"
          :navbarColor="navbarColor"
          />

        <vs-spacer />

<!--        <i18n />-->

        <search-bar class="mt-1 mr-2 sm:mr-6"/>

<!--        <cart-drop-down />-->

        <notification-drop-down />

        <purchase-button />

        <help-dropdown/>

        <profile-drop-down class="ml-3" />

      </vs-navbar>
    </div>
  </div>
</template>

<script>
// Components
import HelpDropdown from '@/layouts/components/navbar/components/HelpDropdown.vue';
import Bookmarks from './components/Bookmarks.vue';
import SearchBar from './components/SearchBar.vue';
import NotificationDropDown from './components/notifications/NotificationDropDown.vue';
import PurchaseButton from './components/PurchaseButton.vue';
import ProfileDropDown from './components/ProfileDropDown.vue';
// import CartDropDown from './components/CartDropDown.vue';
// import I18n from './components/I18n.vue';

export default {
  name: 'the-navbar-vertical',
  components: {
    Bookmarks,
    SearchBar,
    NotificationDropDown,
    PurchaseButton,
    ProfileDropDown,
    HelpDropdown,
    // I18n,
    // CartDropDown,
  },
  props: {
    navbarColor: {
      type: String,
      default: '#fff',
    },
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return { 'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark') };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === 'default') return 'navbar-default';
      if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced';

      return 'navbar-full';
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
  },
};
</script>

<style lang="scss">

@media (max-width: 1200px) {
  .mobile-menu-button {
    display: inline-flex !important;
  }
}
</style>
