<template>
  <div class="flex justify-center"
       @mouseenter="hoveringEmoji=true"
       @mouseleave="hoveringEmoji=false">
    <vx-tooltip
      v-for="(emoji, index) in emojis"
      :key="index"
      :text="emoji.text"
      class="emoji inline-block"
      :class="{
        active: ratingSelected && ratingSelected === emoji.value,
        inactive: ratingSelected && ratingSelected !== emoji.value,
        'mx-2': index > 0 && index < emojis.length -1,
        'mr-2': index === 0,
        'ml-2': index === emojis.length -1,
        }">
      <span
        class="cursor-pointer"
        :class="{flashing: flashing}"
        @click="emojiClicked(emoji)"
        @animationend="flashing = false">
        {{ emoji.emoji }}
      </span>
    </vx-tooltip>
  </div>
</template>

<script>
/**
 * Emojis rating component
 *
 * @module components/EmojisRating
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {number | null} value - rating
 * @vue-data {number | null} ratingSelected - rating selected for user
 * @vue-data {boolean} hoveringEmoji - indicate if user is hovering emojis
 * @vue-data {boolean} flashing - indicate to activate the flashing animation
 * @vue-data {Object[]} emojis - emojis info to show
 * @vue-event {void} emojiClicked - process emoji click
 */
export default {
  name: 'EmojisRating',
  i18n: {
    messages: {
      en: {
        $Ratings: {
          ExtremelyDissatisfied: 'Extremely dissatisfied',
          Dissatisfied: 'Dissatisfied',
          Neutral: 'Neutral',
          Satisfied: 'Satisfied',
          ExtremelySatisfied: 'Extremely satisfied',
        },
      },
    },
  },
  props: {
    value: {
      type: [Number, null],
      required: false,
      default: null,
      validator(value) {
        return value === null
          || (value > 0 && value < 6);
      },
    },
  },
  data() {
    return {
      ratingSelected: this.value,
      hoveringEmoji: false,
      flashing: false,
      emojis: [
        {
          emoji: '😠',
          value: 1,
          text: this.$t('$Ratings.ExtremelyDissatisfied'),
        },
        {
          emoji: '😟',
          value: 2,
          text: this.$t('$Ratings.Dissatisfied'),
        },
        {
          emoji: '😐',
          value: 3,
          text: this.$t('$Ratings.Neutral'),
        },
        {
          emoji: '😊',
          value: 4,
          text: this.$t('$Ratings.Satisfied'),
        },
        {
          emoji: '😍',
          value: 5,
          text: this.$t('$Ratings.ExtremelySatisfied'),
        },
      ],
    };
  },
  watch: {
    value(value) {
      this.ratingSelected = value;
    },
  },
  methods: {
    emojiClicked(emoji) {
      this.ratingSelected = emoji.value;
      this.flashing = true;

      this.$emit('input', emoji.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.emoji {
  font-size: 1.8rem;
  transition: 0.10s;

  &:hover {
    opacity: 1 !important;
    transform: translateY(-10%);
   }

  &.active {
    opacity: 1;

    span {
      &.flashing {
        animation: flash 0.5s;
      }

      @keyframes flash {
        0% {
          box-shadow: none;
        }
        50% {
          box-shadow: rgba(var(--vs-primary), 1) 0px 0px 3px 3px;
        }
        100% {
          box-shadow: none;
        }
      }
    }
  }

  &.inactive {
    opacity: 0.5;
  }

  span {
    border-radius: 40px;
    box-shadow: none;
  }
}
</style>
