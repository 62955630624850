var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout--main",class:[_vm.layoutTypeClass, _vm.navbarClasses, _vm.footerClasses, {'no-scroll': _vm.isAppPage}]},[(!_vm.getUserFlag('welcomeTourSkipped')
        && (_vm.windowWidth >= 1200 && _vm.mainLayoutType === 'vertical'
        && _vm.verticalNavMenuWidth == 'default'))?_c('vx-tour',{attrs:{"steps":_vm.steps},on:{"skipped":_vm.skippedOrFinishedWelcomeTour,"finished":_vm.skippedOrFinishedWelcomeTour}}):_vm._e(),(!_vm.getUserFlag('welcomeTourSkipped')
        && (_vm.windowWidth <= 1201 && _vm.mainLayoutType === 'vertical'
        && _vm.verticalNavMenuWidth == 'no-nav-menu'))?_c('vx-tour',{attrs:{"steps":_vm.stepsMobile},on:{"skipped":_vm.skippedOrFinishedWelcomeTour,"finished":_vm.skippedOrFinishedWelcomeTour}}):_vm._e(),_c('v-nav-menu',{attrs:{"title":_vm.$t('$Brand.Name'),"parent":".layout--main"}}),_c('div',{class:[_vm.contentAreaClass, {'show-overlay': _vm.bodyOverlay}],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),(_vm.mainLayoutType === 'horizontal' && _vm.windowWidth >= 1200)?[_c('the-navbar-horizontal',{class:[
          {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
          {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
        ],attrs:{"navbarType":_vm.navbarType}}),(_vm.navbarType === 'static')?_c('div',{staticStyle:{"height":"62px"}}):_vm._e(),_c('h-nav-menu',{class:[
          {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
          {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
        ],attrs:{"navMenuItems":_vm.navMenuItems}})]:[_c('the-navbar-vertical',{class:[
          {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
          {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
        ],attrs:{"navbarColor":_vm.navbarColor}})],_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content"},[_c('transition',{attrs:{"name":_vm.routerTransition}},[(_vm.$route.meta.pageTitle)?_c('div',{staticClass:"router-header flex flex-wrap flex-row-reverse md:flex-row justify-end md:justify-start items-center mb-6"},[_c('div',{staticClass:"content-area__heading",class:{
                  'pr-4 border-0 md:border-r border-solid border-grey-light': _vm.breadcrumb.length > 1
                  }},[_c('h2',{staticClass:"mb-1",attrs:{"id":"main-page-title"}},[_vm._v(_vm._s(_vm.$tc(_vm.routeTitle, _vm.i18nRouteTitle)))])]),(_vm.breadcrumb.length > 1)?_c('the-breadcrumb',{staticClass:"ml-0 mr-4 md:ml-4 md:mr-0 z-10"}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"content-area__content"},[(!_vm.hideScrollToTop)?_c('back-to-top',{attrs:{"bottom":"5%","right":_vm.$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px',"visibleoffset":"500"}},[_c('vs-button',{staticClass:"shadow-lg btn-back-to-top",attrs:{"icon-pack":"feather","icon":"icon-arrow-up"}})],1):_vm._e(),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{key:_vm.$route.path,on:{"changeRouteTitle":_vm.changeRouteTitle,"setAppClasses":(classesStr) => _vm.$emit('setAppClasses', classesStr)}})],1)],1)],1)])]),_c('the-footer')],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }