var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center",on:{"mouseenter":function($event){_vm.hoveringEmoji=true},"mouseleave":function($event){_vm.hoveringEmoji=false}}},_vm._l((_vm.emojis),function(emoji,index){return _c('vx-tooltip',{key:index,staticClass:"emoji inline-block",class:{
        active: _vm.ratingSelected && _vm.ratingSelected === emoji.value,
        inactive: _vm.ratingSelected && _vm.ratingSelected !== emoji.value,
        'mx-2': index > 0 && index < _vm.emojis.length -1,
        'mr-2': index === 0,
        'ml-2': index === _vm.emojis.length -1,
        },attrs:{"text":emoji.text}},[_c('span',{staticClass:"cursor-pointer",class:{flashing: _vm.flashing},on:{"click":function($event){return _vm.emojiClicked(emoji)},"animationend":function($event){_vm.flashing = false}}},[_vm._v(" "+_vm._s(emoji.emoji)+" ")])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }