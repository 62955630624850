<template>
  <div
    class="layout--main"
    :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

    <!-- Desktop welcome tour -->
    <vx-tour
      v-if="!getUserFlag('welcomeTourSkipped')
        && (windowWidth >= 1200 && mainLayoutType === 'vertical'
        && verticalNavMenuWidth == 'default')"
      :steps="steps"
      @skipped="skippedOrFinishedWelcomeTour"
      @finished="skippedOrFinishedWelcomeTour"/>

    <!-- Mobile welcome tour -->
    <vx-tour
      v-if="!getUserFlag('welcomeTourSkipped')
        && (windowWidth <= 1201 && mainLayoutType === 'vertical'
        && verticalNavMenuWidth == 'no-nav-menu')"
      :steps="stepsMobile"
      @skipped="skippedOrFinishedWelcomeTour"
      @finished="skippedOrFinishedWelcomeTour"/>

    <!-- <the-customizer
      v-if                    = "!disableCustomizer"
      :footerType             = "footerType"
      :hideScrollToTop        = "hideScrollToTop"
      :navbarType             = "navbarType"
      :navbarColor            = "navbarColor"
      :routerTransition       = "routerTransition"
      @toggleHideScrollToTop  = "toggleHideScrollToTop"
      @updateFooter           = "updateFooter"
      @updateNavbar           = "updateNavbar"
      @updateNavbarColor      = "updateNavbarColor"
      @updateRouterTransition = "updateRouterTransition" /> -->

    <v-nav-menu
      :title="$t('$Brand.Name')"
      parent=".layout--main"/>

    <div
      id="content-area"
      :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay" />

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :navbarType= "navbarType"
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />

        <div
          v-if="navbarType === 'static'"
          style="height: 62px" ></div>

        <h-nav-menu
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
          :navMenuItems="navMenuItems" />
      </template>

      <template v-else>
        <the-navbar-vertical
          :navbarColor="navbarColor"
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">
              <div
                v-if="$route.meta.pageTitle"
                class="router-header flex flex-wrap flex-row-reverse md:flex-row justify-end md:justify-start items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{
                  'pr-4 border-0 md:border-r border-solid border-grey-light': breadcrumb.length > 1
                  }">
                  <h2
                    id="main-page-title"
                    class="mb-1">{{ $tc(routeTitle, i18nRouteTitle) }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <the-breadcrumb
                  v-if="breadcrumb.length > 1"
                  class="ml-0 mr-4 md:ml-4 md:mr-0 z-10"/>
              </div>
            </transition>

            <div class="content-area__content">

              <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" :key="$route.path" />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { connectSocket } from '@/socket';

import BackToTop from 'vue-backtotop';
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue';
import navMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems';
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue';
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue';
import TheFooter from '@/layouts/components/TheFooter.vue';
import themeConfig from '@/../themeConfig';
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue';
import TheBreadcrumb from '@/layouts/components/TheBreadcrumb.vue';

const VxTour = () => import('@/components/VxTour.vue');

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    VxTour,
    TheBreadcrumb,
  },
  data() {
    return {
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      navMenuItems,
      routerTransition: themeConfig.routerTransition || 'none',
      // Steps for desktop welcome tour
      steps: [
        {
          target: '#btnVNavMenuMinToggler',
          content: this.$t('$Tours.$WelcomeTour.StepToggleSidebar'),
        },
        {
          target: '.bookmark-container',
          content: this.$t('$Tours.$WelcomeTour.StepBookmarks'),
        },
        {
          target: '.navbar-fuzzy-search',
          content: this.$t('$Tours.$WelcomeTour.StepSearch'),
        },
        {
          target: '.the-footer',
          content: this.$t('$Tours.$WelcomeTour.StepFooter'),
        },
      ],
      // Steps for mobile welcome tour
      stepsMobile: [
        {
          target: '.mobile-menu-button',
          content: this.$t('$Tours.$WelcomeTour.StepToggleSidebar'),
        },
        {
          target: '.navbar-fuzzy-search',
          content: this.$t('$Tours.$WelcomeTour.StepSearch'),
        },
        {
          target: '.the-footer',
          content: this.$t('$Tours.$WelcomeTour.StepFooter'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
    }),
    ...mapState({
      pageTitle: (state) => state.pageTitle,
      breadcrumb: (state) => state.breadcrumb,
    }),
    routeTitle() {
      return this.$route.meta.pageTitle;
    },
    i18nRouteTitle() {
      return this.$route.meta.i18nPageTitle || 2;
    },
    bodyOverlay() { return this.$store.state.bodyOverlay; },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth == 'default') return 'content-area-reduced';
        if (this.verticalNavMenuWidth == 'reduced') return 'content-area-lg';
        return 'content-area-full';
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      return 'content-area-full';
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType == 'hidden',
        'footer-sticky': this.footerType == 'sticky',
        'footer-static': this.footerType == 'static',
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() { return this.$store.state.theme == 'dark'; },
    layoutTypeClass() { return `main-${this.mainLayoutType}`; },
    mainLayoutType() { return this.$store.state.mainLayoutType; },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType == 'hidden',
        'navbar-sticky': this.navbarType == 'sticky',
        'navbar-static': this.navbarType == 'static',
        'navbar-floating': this.navbarType == 'floating',
      };
    },
    verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth; },
    windowWidth() { return this.$store.state.windowWidth; },
  },
  watch: {
    $mq() {
      // Checks if welcome tour is not skipped to later fix position on resize
      if (this.getUserFlag('welcomeTourSkipped') !== true) {
        // Fixes welcome tour position, can also fix some other components position
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 500);
      }
    },
    isThemeDark(val) {
      const color = this.navbarColor == '#fff' && val ? '#10163a' : '#fff';
      this.updateNavbarColor(color);
    },
    '$store.state.mainLayoutType': function (val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
  },
  methods: {
    ...mapActions({
      skippedOrFinishedWelcomeTour: 'auth/skippedOrFinishedWelcomeTour',
    }),
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val == 'static') this.updateNavbarColor(this.isThemeDark ? '#10163a' : '#fff');
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val == '#fff') this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu');
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
    showComponent(name) {
      return this.$route.meta.components && Array.isArray(this.$route.meta.components)
        && this.$route.meta.components.indexOf(name) !== -1;
    },
  },
  created() {
    const color = this.navbarColor == '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch('$store.state.windowWidth', (val) => {
      if (val < 1200) {
        this.disableThemeTour = true;
        this.dynamicWatchers.windowWidth();
      }
    });

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch('$store.state.verticalNavMenuWidth', () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.verticalNavMenuWidth();
    });

    this.dynamicWatchers.rtl = this.$watch('$vs.rtl', () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });

    const $html = document.getElementsByTagName('html')[0];

    $html.classList.remove('aeroland');
    document.body.classList.remove('aeroland');
    $html.classList.add('vuexy');
    document.body.classList.add('vuexy');
  },
  mounted() {
    connectSocket();
  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map((i) => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  },
};

</script>

<style lang="scss">

@media (max-width: 767px) {
  .router-header {
    position: relative;
    align-items: flex-start !important;
    .content-area__heading {
      width: calc(100% - 50px);
    }

    .vx-breadcrumb {
      position: relative;
      top: 0px;
      height: 100%;
      width: 35px;
      margin-right: 6px !important;
      .feather-icon {
        border-radius: 50px;
        padding: 5px;
      }
    }
  }
}
</style>
