<template>
  <footer
    class="the-footer justify-center"
    :class="classes">
<!--    <span class="pr-4 border-0 border-r border-solid border-grey-light">-->
<!--      <terms-and-conditions-link>-->
<!--        <feather-icon-->
<!--          icon="FileTextIcon"-->
<!--          svgClasses="w-4 h-4"/>-->
<!--        <span class="ml-1">{{ $tc('$General.TermsAndConditions') }}</span>-->
<!--      </terms-and-conditions-link>-->
<!--    </span>-->

    <feedback-button
      v-if="roleIsMerchant"
      class="mx-4"/>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import FeedbackButton from '@/layouts/components/feedback/TheFeedbackButton.vue';
import TermsAndConditionsLink from '@/layouts/components/TermsAndConditionsLink.vue';

/**
 * The footer app
 *
 * @module layouts/components/TheFooter
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'the-footer',
  components: {
    FeedbackButton,
    TermsAndConditionsLink,
  },
  props: {
    classes: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      roleIsMerchant: 'auth/roleIsMerchant',
    }),
  },
};

</script>
