<template>
  <div>
    <label
      class="vs-input--label">
      {{ $t('TextAreaLabel') }}
    </label>
    <vs-textarea
      v-model="localValue"
      :class="{'border-danger': errors.has($t('TextAreaName'))}"
      style="min-height: 8rem !important;"/>
    <vs-input
      v-model="localValue"
      type="hidden"
      class="w-full"
      :name="$t('TextAreaName')"
      v-validate="'required|max:500'"
      :danger="errors.has($t('TextAreaName'))"
      :danger-text="errors.first($t('TextAreaName'))"/>
  </div>
</template>

<script>
/**
 * comments for feedback modal
 *
 * @module layouts/components/feedback/FeedbackModalComments
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} value - comments
 * @vue-data {string} localValue - comments entered by the user
 */
export default {
  name: 'TheFeedbackModalComments',
  i18n: {
    messages: {
      en: {
        TextAreaLabel: 'What would you like to share with us?',
        TextAreaName: 'Comment',
      },
    },
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
    localValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
