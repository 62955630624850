<template>
  <vs-dropdown
    vs-custom-content
    vs-trigger-click
    class="cursor-pointer">

    <feather-icon
      icon="HelpCircleIcon"
      class="cursor-pointer mt-1 mr-1 sm:mr-3"/>

    <vs-dropdown-menu class="vx-navbar-dropdown">
      <ul style="min-width: 9rem">
<!--        <li-->
<!--          class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"-->
<!--          @click="$router.push('/faq')">-->
<!--          <span>-->
<!--            <i class="fas fa-question"></i>-->
<!--          </span>-->
<!--          <span class="ml-2">{{ $tc('$General.FAQ', 2) }}</span>-->
<!--        </li>-->

<!--        <li-->
<!--          class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"-->
<!--          @click="$router.push('/documentation')">-->
<!--          <feather-icon-->
<!--            icon="BookOpenIcon"-->
<!--            svgClasses="w-4 h-4" />-->
<!--          <span class="ml-2">{{ $t('$General.Documentation') }}</span>-->
<!--        </li>-->

        <li
          class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
          @click="increaseFeedbackModalKey()">
          <span>
            <i class="far fa-comments"></i>
          </span>
          <span class="ml-2">{{ $t('$General.Contact') }}</span>
        </li>
      </ul>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import { mapMutations } from 'vuex';

/**
 * Help dropdown
 *
 * @module layouts/components/navbar/components/HelpDropdown
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'HelpDropdown',
  methods: {
    ...mapMutations({
      increaseFeedbackModalKey: 'INCREASE_FEEDBACK_MODAL_KEY',
    }),
  },
};
</script>
