<template>
  <div class="w-full">
    <label
      class="vs-input--label">
      {{ $t('Label') }}
    </label>
    <v-select
      ref="vSelect"
      v-model="valueLocal"
      class="w-full"
      :options="feedbackTypeOptions"
      append-to-body
      :multiple="false"
      :clearable="false"
      close-on-select
      :getOptionLabel="(option) => $t(`$Enums.AppFeedbackType.${option}`)"
      @search:focus="applyModalZIndexToDropDown()"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';

// Mixins
import commonVSelect from '@/views/modules/_mixins/commonVSelect';

/**
 * Select type for feedback modal
 *
 * @module layouts/components/feedback/FeedbackModalSelectType.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} value - type of rating
 * @vue-data {string} valueLocal - type of rating selected for the user
 * @vue-data {string[]} feedbackTypeOptions - feedback type options for select
 */
export default {
  name: 'TheFeedbackModalSelectType',
  i18n: {
    messages: {
      en: {
        Label: 'Tell us more?',
      },
    },
  },
  components: {
    vSelect,
  },
  mixins: [commonVSelect],
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      valueLocal: this.value,
      feedbackTypeOptions: Object.values(this.$enums.AppFeedbackType),
    };
  },
  watch: {
    value(val) {
      this.valueLocal = val;
    },
    valueLocal(val) {
      this.$emit('input', val);
    },
  },
};
</script>
